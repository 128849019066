import React from 'react';
import styled from 'styled-components';

import { Body4Bold, P } from './Typography';
import GooglePlayBg from '../assets/icons/googlePlayBg.svg';
import AppleStoreBg from '../assets/icons/appStoreBg.svg';
import ButtonDefault from './ButtonDefault';
import TickedItems from './TickedItems';

const Wrapper = styled.div`
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-color: gray;
  height: calc(25rem - 4rem);
  border-radius: 40px;
  padding: 4rem 6rem;
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    height: auto;
    padding: 2.5rem 2rem;
    background-position: left;
  }
`;

const Content = styled.div`
  width: 35%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
const Label = styled.p`
  font-size: 40px;
  font-weight: bold;
  color: white;
  line-height: 42px;
  margin-bottom: 2rem;
`;
const Description = styled(P)`
  color: white;
  margin-bottom: 2rem;
`;

const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
  /* display: none; */
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const Caption = styled(Body4Bold)`
  color: #2cd19e;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

const StyledButtonDefault = styled(ButtonDefault)<{ buttonColor: string }>`
  background: ${(props) =>
    props.buttonColor ? `${props.buttonColor}` : `initital`};
`;
interface BannerProps {
  bgSrc: string;
  label: string;
  descriptionItems?: {
    item: string;
  }[];
  description?: string;
  appStoreButtonUrl?: string;
  googlePlayButtonUrl?: string;
  buttonUrl?: string;
  buttonLabel?: string;
  buttonColor?: string;
  bannerCaption?: string;
}
const Banner: React.FunctionComponent<BannerProps> = ({
  bgSrc,
  label,
  descriptionItems,
  description,
  appStoreButtonUrl,
  googlePlayButtonUrl,
  buttonUrl,
  buttonLabel,
  buttonColor,
  bannerCaption,
}) => (
  <Wrapper resource={bgSrc}>
    <Content>
      <Caption>{bannerCaption}</Caption>
      <Label>{label}</Label>
      {descriptionItems && (
        <TickedItems
          textColor="#fff"
          tickColor="#fff"
          items={descriptionItems}
        />
      )}
      <Description>{description}</Description>
      <Buttons>
        {appStoreButtonUrl && googlePlayButtonUrl && (
          <>
            <ButtonDefault withImage to={appStoreButtonUrl}>
              <AppleStoreBg />
            </ButtonDefault>
            <ButtonDefault withImage to={googlePlayButtonUrl}>
              <GooglePlayBg />
            </ButtonDefault>
          </>
        )}
      </Buttons>
      {buttonUrl && buttonLabel && (
        <StyledButtonDefault buttonColor={buttonColor} to={buttonUrl}>
          {buttonLabel}
        </StyledButtonDefault>
      )}
    </Content>
  </Wrapper>
);

export default Banner;
