import React from 'react';
import styled from 'styled-components';
import { P } from './Typography';
import HeaderCardCounter from './HeaderCardCounter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 30%;
  border-radius: 10px;
  box-shadow: 0px 0px 26px #e7edf3;
  margin-right: 1rem;
  background-color: #e5eaf2;
  &:last-child {
    margin-right: 0;
    svg {
      display: none;
    }
  }
  @media (max-width: 1200px) {
    flex-basis: 100%;
    margin-bottom: 1rem;
    margin-right: 0;
    svg {
      display: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Content = styled.div`
  padding: 1.5rem 1rem;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled(P)`
  margin-top: 1rem;
`;

const Img = styled.img`
  width: 100%;
  @media (max-width: 1200px) {
    max-width: 100%;
  }
`;

interface OpenBankingCardProps {
  number: string;

  text: string;
  imageSrc: string;
}
const HowToCard: React.FunctionComponent<OpenBankingCardProps> = ({
  number,
  text,
  imageSrc,
}) => (
  <Wrapper>
    <Content>
      <HeaderCardCounter
        borderColor="#C2CEDB"
        arrowColor="#8181A5"
        number={number}
      />
      <Text>{text}</Text>
    </Content>

    <Footer>
      <Img src={imageSrc} alt="card logo" />
    </Footer>
  </Wrapper>
);

export default HowToCard;
