import React from 'react';
import styled from 'styled-components';
import { Subtitle5 } from './Typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 170px;
  width: 170px;
  /* @media (max-width: 1200px) {
    flex-grow: 1;
    margin-bottom: 6rem;
  } */
`;
const Link = styled.a`
  height: 100%;
`;
const ImgWrapper = styled.div`
  background-color: #f2f4f7;
  height: 170px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #fff;

  @media (max-width: 1200px) {
    justify-content: center;
  }
`;
const Img = styled.img<{ maxWidthPercentage?: string }>`
  display: flex;
  align-items: center;
  min-width: 70%;
  max-width: ${({ maxWidthPercentage }) => maxWidthPercentage ?? `90%`};
`;
const Title = styled(Subtitle5)`
  margin-bottom: 0.5rem;
`;
interface SolutionComponentProps {
  title?: string;
  imageSrc: string;
  link?: string;
  maxWidthPercentage?: string;
}
const CbdPluginCard: React.FunctionComponent<SolutionComponentProps> = ({
  title,
  imageSrc,
  link,
  maxWidthPercentage,
}) => (
  <Wrapper>
    <Title>{title}</Title>
    <Link target="_blank" href={link} rel="noreferrer">
      <ImgWrapper>
        <Img
          maxWidthPercentage={maxWidthPercentage}
          src={imageSrc}
          alt="card logo"
        />
      </ImgWrapper>
    </Link>
  </Wrapper>
);

export default CbdPluginCard;
