import React from 'react';
import styled from 'styled-components';
import { CbdRetailProps } from '@/pages/marketing/cbd-retail-and-wholesale';
import FirstViewport from '@/components/FirstViewport';
import {
  Description,
  H1,
  H2,
  H3,
  P,
  SecondaryP,
  StrongInitial,
  Subtitle2,
} from '@/components/Typography';
import Vp1Image from '../assets/img/cbdVp1.png';
import ButtonDefault from '@/components/ButtonDefault';
import Section from '@/components/Section';
import CbdPluginCard from '@/components/CbdPluginCard';
import CarouselCard from '@/components/CarouselCard';
import RefreshIcon from '../assets/icons/refresh.svg';
import LinkIcon from '../assets/icons/link.svg';
import invoiceCardLeft from '../assets/img/invoiceCardLeft.png';
import invoiceCardRight from '../assets/img/invoiceCardRight.png';
import PluginCard from '@/components/PluginCard';
import PayByLinkCard from '@/components/PayByLinkCard';
import Vp3Image from '../assets/img/accountantsVp5.png';
import TickedItems from '@/components/TickedItems';
import HowToCard from '@/components/HowToCard';
import Banner from '@/components/Banner';
import bannerBg from '../assets/img/bannerInStorePaymentsBg.png';

const SecondViewport = styled(Section)`
  background-color: #fff;
  padding-bottom: 10rem;
`;

const ThirdWpWrapper = styled.div`
  background: linear-gradient(
    31.87deg,
    rgba(255, 255, 255, 0.825) 20.76%,
    rgba(228, 230, 240, 0.2) 82.32%
  );
`;

const ThirdViewport = styled(Section)`
  background-color: rgba(255, 255, 255, 0.156);
`;

const FourthVpWrapper = styled.div`
  background-color: #fff;
`;

const FourthViewport = styled(Section)`
  padding-top: 2rem;
  background: linear-gradient(
    9deg,
    #c2cedb -4.09%,
    rgba(255, 255, 255, 0) 64.72%
  );
`;

const FifthViewport = styled(Section)`
  background-color: #fff;
`;

const SixthWbWrapper = styled.div`
  background-color: #fff;
`;

const SixthViewport = styled(FirstViewport)`
  padding-top: 0;
  padding-bottom: 0;
`;

const SeventhViewport = styled(Section)`
  background-color: #fff;
  padding-top: 3rem;
`;

const Vp2Description = styled(Description)`
  width: 70%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const PluginCardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;

const Label = styled(H3)`
  text-align: center;
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const InvoiceCardsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 3rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const InvoiceCard = styled.div`
  border-radius: 20px;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 5rem;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 1200px) {
    margin-bottom: 2rem;
    flex-grow: 1;
    flex-basis: 100%;
    margin-right: 0;
  }
`;

const InvoceCardLabel = styled(Subtitle2)`
  margin: 1rem 0;
`;

const InvoiceCardLeft = styled(InvoiceCard)`
  background: linear-gradient(341.28deg, #c2cedb -38.66%, #f4f7f9 130.75%);
`;
const InvoiceCardRight = styled(InvoiceCard)`
  background: linear-gradient(128.69deg, #14bd89 11.83%, #47ef90 86.41%);
`;

const InvoiceCardImg = styled.img`
  align-self: flex-end;
  width: 80%;
  @media (max-width: 1200px) {
    border-bottom-right-radius: 20px;
  }
`;

const InvoiceCardTop = styled.div`
  padding: 3rem 3rem 0 3rem;
  @media (max-width: 1200px) {
    padding: 1rem 1rem 0 1rem;
  }
`;

const Vp3Description = styled(P)`
  text-align: center;
  margin-top: 1rem;
  @media (max-width: 1200px) {
    text-align: start;
  }
`;

const PayByLinkCardsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  flex-wrap: wrap;
`;

const StyledStrong = styled(StrongInitial)`
  margin-top: 2rem;
`;

const DescriptionWrapper = styled.div`
  margin: 2rem 0;
`;

const VpDescription = styled(P)`
  text-align: center;
  margin-top: 1rem;
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const HowToCardsWrap = styled.div`
  display: flex;
  margin-top: 2rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const WrapperBanner = styled.div`
  margin-top: 10rem;
`;
interface CbdProps {
  content: CbdRetailProps;
}

const CbdRetailContainer: React.FunctionComponent<CbdProps> = ({
  content: {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
  },
}) => (
  <>
    <FirstViewport img={Vp1Image}>
      <H1>
        {viewport1.title1}
        {` `}
        <span className="accent-text">{viewport1.title2}</span>
        {` `}
        {viewport1.title3}
        {` `}
        <span className="accent-text">{viewport1.title4}</span>
      </H1>
      <Description className="accent-text-black-bold">
        {viewport1.description1}
        {` `}
        <span className="accent-text-red">{viewport1.description2}</span>
        {` `}
        {viewport1.description3}
      </Description>

      <ButtonDefault to={viewport1.button.url}>
        {viewport1.button.label}
      </ButtonDefault>
    </FirstViewport>
    <SecondViewport>
      <H2>
        {viewport2.title1}
        {` `}
        <span className="accent-text">{viewport2.title2}</span>
        {` `}
        {viewport2.title3}
      </H2>
      <Vp2Description>{viewport2.description}</Vp2Description>
      <PluginCardWrapper>
        {viewport2.pluginCards.map((item) => (
          <CbdPluginCard
            key={item.label}
            title={item.label}
            imageSrc={item.image}
          />
        ))}
      </PluginCardWrapper>
    </SecondViewport>
    <ThirdWpWrapper>
      <ThirdViewport>
        <Label>{viewport3.label}</Label>
        <CardWrapper>
          {viewport3.benefitCards.map((item) => (
            <CarouselCard
              key={item.label}
              flexBasis="25%"
              marginBottom="2rem"
              title={item.label}
              text={item.text}
              cardImgSrc={item.image}
            />
          ))}
        </CardWrapper>
        <Label>{viewport3.label2}</Label>
        <Vp3Description>{viewport3.description}</Vp3Description>
        <InvoiceCardsWrapper>
          <InvoiceCardLeft>
            <InvoiceCardTop>
              <RefreshIcon />
              <InvoceCardLabel>
                {viewport3.leftCardLabel1}
                {` `}
                <span className="accent-text">{viewport3.leftCardLabel2}</span>
              </InvoceCardLabel>
              <P>{viewport3.leftCardText}</P>
            </InvoiceCardTop>

            <InvoiceCardImg src={invoiceCardLeft} alt="code screenshot" />
          </InvoiceCardLeft>
          <InvoiceCardRight>
            <InvoiceCardTop>
              <LinkIcon />
              <InvoceCardLabel>
                <span className="accent-text-white">
                  {viewport3.rightCardLabel1}
                </span>
                {` `}
                {viewport3.rightCardLabel2}
              </InvoceCardLabel>
              <P className="accent-text-white">{viewport3.rightCardText}</P>
            </InvoiceCardTop>

            <InvoiceCardImg src={invoiceCardRight} alt="code screenshot" />
          </InvoiceCardRight>
        </InvoiceCardsWrapper>
      </ThirdViewport>
    </ThirdWpWrapper>
    <FourthVpWrapper>
      <FourthViewport>
        <Label>{viewport4.label}</Label>
        <PluginCardWrapper>
          {viewport4.pluginCards.map((item) => (
            <PluginCard
              key={item.label}
              title={item.label}
              imageSrc={item.image}
            />
          ))}
        </PluginCardWrapper>
      </FourthViewport>
    </FourthVpWrapper>
    <FifthViewport>
      <Label>{viewport5.label}</Label>
      <Vp3Description>{viewport5.description}</Vp3Description>
      <PayByLinkCardsWrap>
        {viewport5.payByLinkCards.map((item) => (
          <PayByLinkCard
            key={item.label}
            number={item.count}
            label={item.label}
            text={item.text}
            imageSrc={item.image}
          />
        ))}
      </PayByLinkCardsWrap>
    </FifthViewport>
    <SixthWbWrapper>
      <SixthViewport
        padding="0 calc((100vw - 1200px) / 2)"
        imgWidth="50%"
        withRightPadding
        img={Vp3Image}
      >
        <SecondaryP className="accent-text-gray">{viewport6.label}</SecondaryP>
        <H1>
          {viewport6.title1}
          {` `}
          <span className="accent-text">{viewport6.title2}</span>
        </H1>
        <StyledStrong>{viewport6.boldDescription}</StyledStrong>
        <DescriptionWrapper>
          <TickedItems items={viewport6.descriptionItems} />
        </DescriptionWrapper>
      </SixthViewport>
      <SeventhViewport>
        <Label>{viewport7.title}</Label>
        <VpDescription>{viewport7.description}</VpDescription>
        <HowToCardsWrap>
          {viewport7.howToCards.map((item) => (
            <HowToCard
              key={item.count}
              number={item.count}
              text={item.text}
              imageSrc={item.image}
            />
          ))}
        </HowToCardsWrap>
        <WrapperBanner>
          <Banner
            bgSrc={bannerBg}
            label={viewport7.bannerLabel}
            description={viewport7.bannerDescription}
            buttonUrl={viewport7.button.url}
            buttonLabel={viewport7.button.label}
            buttonColor="#2CD19E"
          />
        </WrapperBanner>
      </SeventhViewport>
    </SixthWbWrapper>
  </>
);

export default CbdRetailContainer;
