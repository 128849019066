import React from 'react';
import styled from 'styled-components';
import ArrowRight from '../assets/icons/arrowRight.svg';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Arrow = styled(ArrowRight)<{ color: string }>`
  color: ${({ color }) => color && `${color}`};
`;

const Number = styled.div<{ borderColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${({ borderColor }) => borderColor && `${borderColor}`};
`;

const NumberP = styled.div`
  padding-top: 2px;
`;
interface HeaderCardProps {
  number: string;
  borderColor: string;
  arrowColor: string;
}
const HeaderCardCounter: React.FunctionComponent<HeaderCardProps> = ({
  number,
  borderColor,
  arrowColor,
}) => (
  <Header>
    <Number borderColor={borderColor}>
      <NumberP>{number}</NumberP>
    </Number>
    <Arrow color={arrowColor} />
  </Header>
);

export default HeaderCardCounter;
