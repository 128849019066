import React from 'react';
import styled from 'styled-components';
import { P, Subtitle5 } from './Typography';
import HeaderCardCounter from './HeaderCardCounter';

const Wrapper = styled.div<{ backgroundColor: string }>`
  ${(props) =>
    props.backgroundColor && `background-color: ${props.backgroundColor}`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 30%;
  border-radius: 10px;
  box-shadow: 0px 0px 26px #e7edf3;

  &:last-child {
    svg {
      display: none;
    }
  }
  @media (max-width: 1200px) {
    flex-basis: 100%;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    svg {
      display: none;
    }
  }
`;

const Content = styled.div`
  padding: 2rem;
  min-height: 10rem;
  @media (max-width: 1200px) {
    padding: 1rem;
  }
`;

const Label = styled(Subtitle5)``;

const GreenLabel = styled(Label)`
  margin-bottom: 0;
`;

const Footer = styled.div<{ withPadding?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(81.7deg, #e7edf3 8.96%, #e4e6f0 104.78%);
  ${(props) => props.withPadding && `padding: 2rem`};
  min-height: 4rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  @media (max-width: 1200px) {
    padding: 0.5rem 0;
  }
`;

const CounterWrapper = styled.div`
  margin-bottom: 1rem;
`;

const Img = styled.img`
  @media (max-width: 1200px) {
    width: 80%;
  }
`;

const Text = styled(P)`
  margin-top: 1rem;
`;
interface PayByLinkCardProps {
  number?: string;
  label: string;
  text: string;
  imageSrc: string;
  backgroundColor?: string;
  greenTitle?: string;
}
const PayByLinkCard: React.FunctionComponent<PayByLinkCardProps> = ({
  number,
  label,
  text,
  imageSrc,
  backgroundColor,
  greenTitle,
}) => (
  <Wrapper backgroundColor={backgroundColor}>
    <Content>
      {number && (
        <CounterWrapper>
          <HeaderCardCounter
            borderColor="#DBE3EB"
            arrowColor="#DBE3EB"
            number={number}
          />
        </CounterWrapper>
      )}
      {greenTitle && (
        <GreenLabel className="accent-text">{greenTitle}</GreenLabel>
      )}
      <Label>{label}</Label>
      <Text>{text}</Text>
    </Content>

    <Footer withPadding={!!number}>
      <Img src={imageSrc} alt="card logo" />
    </Footer>
  </Wrapper>
);

export default PayByLinkCard;
